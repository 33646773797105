import { mapGetters } from "vuex";
export default {
  data() {
    return {
      iFrame:'',
      Sing_in_Url:'',
      private_iFrame:'',
      public_Iframe:'',
      signIn :false,
    };
  },
  async mounted() {
  },
  computed: { ...mapGetters("sso",["getAuthenticatedSSOToken"]),
  ...mapGetters("company", ["getContactLoginSlug","getUpdateCompany","getCompanyDetails"]),
  ...mapGetters("iFrame",["getIframeUrlInfo"]),
},
  methods: {
    getNavigationQuery(query) {
      let q = {
        key: Math.floor(Math.random() * 100000),
      };
      query.group ? (q.group = query.group) : "";
      query.groups ? (q.groups = query.groups) : "";
      query.t ? (q.t = query.t) : "";
      query.routeType ? (q.routeType = query.routeType) : "";
      query.filter ? (q.filter = query.filter) : "";
      query.customization ? (q.customization = query.customization) : "";
      query.fromCustom ? (q.fromCustom = query.fromCustom) : "";
      query.menu_id ? (q.menu_id = query.menu_id) : "";
      query.dataId ? (q.dataId = query.dataId) : "";
      query.stepId ? (q.stepId = query.stepId) : "";
      query.layout ? (q.layout = query.layout) : "";
      query.viewType ? (q.viewType = query.viewType) : "";
      query.activeTab ? (q.activeTab = query.activeTab) : "";
      query.formbuilder_data_id ? (q.formbuilder_data_id = query.formbuilder_data_id) : "";
      query.redirect && query.redirect != 'undefined' ? (q.redirect = query.redirect ) : "";
      query.addNew ? (q.addNew = query.addNew) : ""; 
      query.isMenu == 'true' ? (q.isMenu = 'true') : "";  
      query.order_by ? (q.order_by = query.order_by) : "";
      query.order_type ? (q.order_type = query.order_type) : "";
      return q;
    },
    urlBuilder(query) {
      let url = "";
      url = url + `key=${Math.floor(Math.random() * 100000)}`;
      if (query.group) url = url + `&group=${query.group}`;
      if (query.groups) url = url + `&groups=${query.groups}`;
      if (query.t) url = url + `&t=${query.t}`;
      if (query.routeType) url = url + `&routeType=${query.routeType}`;
      if (query.filter) url = url + `&filter=${query.filter}`;
      if (query.fromCustom) url = url + `&fromCustom=${query.fromCustom}`;
      if (query.menu_id) url = url + `&menu_id=${query.menu_id}`;
      if (query.dataId) url = url + `&dataId=${query.dataId}`;
      if (query.stepId) url = url + `&stepId=${query.stepId}`;
      if (query.layout) url = url + `&layout=${query.layout}`;
      if (query.viewType) url = url + `&viewType=${query.viewType}`;
      if (query.activeTab) url = url + `&activeTab=${query.activeTab}`;
      if(query.tab) url = url + `&tab=${query.tab}`;
      if(query.customization) url = url + `&customization=${query.customization}`;
      if(query.subTab) url = url + `&subTab=${query.subTab}`
      if(query.subType) url = url + `&subType=${query.subType}`
      if(query.tabOrd) url = url + `&tabOrd=${query.tabOrd}`
      if(query.addNew) url = url + `&addNew=${query.addNew}`
      if(query.redirect) url= url + `&redirect=${query.redirect}`
      return url;
    },

    async isIframe(){
      const currentPath = this.$route.path;
      if (this.$route.params.ssoAuth) {
        this.iFrame = currentPath
        let params = {
          id: this.$route.params.ssoAuth,
        }
        await this.$store.dispatch("iFrame/fetchIframeUrlTypes", params);
      }
   },
     checkUrlType() {
       if (this.getIframeUrlInfo?.url_type == 'PRIVATE') {
         return 'private';
       }
       else if (this.getIframeUrlInfo?.url_type == 'PUBLIC') {
         return 'public';
       }
       else {
         return 'SignIn'
       }
     },
     getPermissionsForIds(ids) {
      const permissionsTemplate = {
        VIEW: true,
        EDIT: false,
        ADD: true
      };
      let data = {}
      ids.map(id => (
        data[id] = permissionsTemplate
      ))
      return data
    },
    generateOutputFromUrl(url, excludedKeys = []) {
      let urlParts = url.split("?");
      let path = urlParts[0];
      let queryParams = urlParts.length > 1 ? urlParts[1].split("&") : [];
      let pathSegments = path.split("/");
      let keyValuePairs = {};
      queryParams.forEach((param) => {
        let [key, value] = param.split("=");
        if (!excludedKeys.includes(key)) {
          keyValuePairs[key] = value;
        }
      });
      let output = "";

      pathSegments.forEach((segment, index) => {
        if (index !== 0 && index !== 1) {
          output += `#${segment}`;
        } else {
          output += segment;
        }
      });
      for (const [key, value] of Object.entries(keyValuePairs)) {
        output += `#${key}=${value}`;
      }
      return output;
    },
     IframePermissions(access,type){
      if (this.getIframeUrlInfo != null) {
        const hasAccess = this.getIframeUrlInfo.permissions.selectedPermissions.options.includes(access);
        const viewType =this.getIframeUrlInfo.permissions.selectedPermissions.type === type
        if( viewType){
        switch (access) {
            case "ADD":
            case "VIEW":
            case "EDIT":
            case "DELETE":
            case "EXPORT" :
            case "IMPORT" :
            case "SEND NOTIFICATION":
            case "FILTERS":
            case "LAYOUT OPTIONS":
            case "SETTINGS":
            case "LAYOUT" :
            case "GET LINK" :
            case "BULK UPDATE" :
            case "DETAILED VIEW" :
            case "ATTACHMENT":
            case "RENAME TEMPLATE":
            case "CHANGE OWNER":
            case "SHARE":
            case "BULK SEND":
            case "PRINT CHEQUE":
            case "DOWNLOAD ORIGINAL":
            case "CREATE DOCUMENT":
                return hasAccess;
            default:
                console.log("Unknown access type:", access);
                return false;
        }
      }
      else{
        return true;
      }
    } else {
        return true; 
    }
  }
  },
};
